<template>
    <div>
        <!-- <CampaignFinancialCourse v-if="$root.user.hasBalcaoMais" class="mb-4" /> -->
        <!-- <CampaignReturnToPortugal v-if="$root.user.hasBalcaoMais" class="mb-4" /> -->
        <ShowLastBussola v-if="$root.user.hasBalcaoMais" />
        <ListMedals />
        <ShowAgenda v-if="$root.user.hasBalcaoMais" />
        <News v-if="$root.processEnv !== 'development'" />
    </div>
</template>

<script>
import News from './News.vue';
import ShowAgenda from './ShowAgenda.vue';
import ShowLastBussola from '../bussola/ShowLastBussola.vue';
import ListMedals from '../medals/ListMedals.vue';
// import CampaignReturnToPortugal from './campaign/CampaignReturnToPortugal.vue';
// import CampaignFinancialCourse from './campaign/CampaignFinancialCourse.vue';

export default {
    components: { News, ShowAgenda, ShowLastBussola, ListMedals },
}
</script>