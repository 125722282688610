<template>
  <div class="mb-3 p-2 block post-card">
    <div class="col-12">
      <div v-if="post.postType === 'Aniversários'">
        <small>
          {{ post.description }}
          <span>{{ $root.formatDate(post.createdAt) }}</span>
          <br />
        </small>
        <img :src="$root.images.birthDate" class="post-image-birthday" />
        <span class="text-center" v-html="$sanitize(post.message)" />
      </div>
      <div v-else class="space-between mb-2">
        <div v-if="post.user" class="flex mr-2">
          <ShowUserImage v-if="post.user.imgUrl" :url="showUser
            ? $root.getUrlFromFilePath(post.user.imgUrl)
            : $root.user.hasBalcaoMais
              ? $root.images.balcaoMaisIcon
              : $root.user.realm == 'BE'
                ? $root.images.beLogoBall
                : $root.images.pafSymbol" img-class="rounded " width="50" height="50" />
          <div class="ml-2">
            <div v-if="!fromProcess && post.processId && post.postType === 'Processos'" class="mr-2">
              <b>{{ post.user.name }}</b><span class="mx-1 lowercase">{{ $t('in_the_process') }}</span>
              <router-link :to="{ name: 'process', params: { id: post.processId } }">
                <strong>{{ post.processTypeName }}</strong>
              </router-link>
            </div>
            <span v-else class="mr-2">
              {{ showUser
                ? post.user.name
                : $root.user.hasBalcaoMais
                  ? "Balcão+ "
                  : $root.user.realm == "BE"
                    ? "Balcão do Emigrante"
                    : "Grupo ParcialFinance"
              }}
            </span>
            <small class="mt-1 text-600 d-grid">{{ $root.formatDateTime(post.createdAt) }}</small>
          </div>
        </div>
        <div v-else class="mb-2">
          <small>{{ $root.formatDateTime(post.createdAt) }}</small>
        </div>
      </div>
      <b>{{ post.description }}</b>
      <p class="mb-0 mt-2" style="overflow-wrap: break-word" v-html="$sanitize(post.message)" />
    </div>
    <div v-if="post.files && post.files.length > 0 && post.type !== 'Processos'" class="flex flex-wrap">
      <PostFile v-for="(file, index) in post.files" :key="file.url" :file="file" class="m-1" display-name
        @show-file="setDisplayFiles(post.files, index)" />
    </div>

    <!--Comments-->
    <div class="mt-2">
      <div v-for="comment in post.comments" :key="comment.id" class="pt-1 flex">
        <div v-if="comment.user" class="mr-2 mt-1">
          <ShowUserImage :url="comment.user.type == 'Client'
            ? $root.getUrlFromFilePath(comment.user.imgUrl)
            : $root.user.hasBalcaoMais
              ? $root.images.balcaoMaisIcon
              : $root.user.realm == 'BE'
                ? $root.images.beLogoBall
                : $root.images.pafSymbol" img-class="rounded " width="30" height="30" />
        </div>
        <div class="ml-2 p-2 post-comment-card w-full word-break-all">
          <b class="mr-2">
            {{
              comment.user.type == "Client"
                ? comment.user.name
                : $root.user.hasBalcaoMais
                  ? "Balcão+ "
                  : $root.user.realm == "BE"
                    ? "Balcão do Emigrante"
                    : "Grupo ParcialFinance"
            }}
          </b>
          <b class="mr-1">·</b>
          <small>{{ $root.formatDate(comment.createdAt) }} {{ $root.formatTime(comment.createdAt) }}</small>
          <br />
          <p class="mb-0 mt-2" style="overflow-wrap: break-word" v-html="$sanitize(comment.message)" />

          <div v-if="comment.files && comment.files.length > 0" class="flex flex-wrap">
            <PostFile v-for="(file, index) in comment.files" :key="file.url" :file="file" class="m-1" display-name
              @show-file="setDisplayFiles(comment.files, index)" />
          </div>
        </div>
      </div>
      <CreateComment v-if="!post.processId && ('receiver' in post) && post.receiver && ('clients' in post.receiver) &&
        post.receiver.clients.length == 1 && post.receiver.clients[0] == $root.user.name" :id="post.id" class="mt-2" @comment-created="getComments" />
      <router-link v-if="post.processId && !fromProcess" :to="{ name: 'process', params: { id: post.processId } }"
        class="mt-4">Ir
        para o processo</router-link>
    </div>
    <DialogFiles :files="filesToDisplay" :display-files="displayFiles" :file-selected-index="fileSelectedIndex"
      @closed="displayFiles = false" />
  </div>
</template>

<script>
import DialogFiles from "../files/DialogFiles.vue";
import PostFile from "./PostFile.vue";
import ShowUserImage from "../files/ShowUserImage.vue";
import CreateComment from "./CreateComment.vue";

export default {
  components: { PostFile, CreateComment, DialogFiles, ShowUserImage },
  props: {
    post: { type: Object, default: null },
    showRealUser: { type: Boolean, default: false },
    fromProcess: { type: Boolean, default: false }
  },
  data() {
    return {
      displayFiles: false,
      fileSelectedIndex: 0,
      filesToDisplay: [],
    };
  },
  computed: {
    showUser() {
      return this.post.user.type == 'Client' || this.showRealUser || this.post.postType == 'Processos'
        || (this.post.receiver && ('clients' in this.post.receiver) && this.post.receiver.clients.length == 1
          && this.post.receiver.clients[0] == this.$root.user.name);
    }
  },
  methods: {
    getComments() {
      this.$root
        .apiGet(this.$root.api.postComments, {
          params: { postId: this.post.id },
        })
        .then((response) => {
          this.post.comments = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    setDisplayFiles(files, index) {
      this.filesToDisplay = files;
      this.fileSelectedIndex = index;
      this.displayFiles = true;
    },
  },
};
</script>
